import blog1 from"./Images/How WhatsApp Chatbots Can Revolutionize Customer Support for Indian SMEs.jpg";
import blog2 from"./Images/How to Use WhatsApp Business API to Automate Customer Communication.jpg";
import blog3 from"./Images/How to Use WhatsApp Business API to Automate Customer Communication.jpg";
import blog4 from"./Images/WhatsApp Business API Pricing in India_ Everything You Need to Know.jpg";
import blog5 from"./Images/Top 10 Benefits of WhatsApp Chatbots   for Small Businesses in Bangalore.jpg";
import blog6 from"./Images/Step-by-Step Guide to Integrating WhatsApp Business API with Your CRM.jpg";
import blog7 from"./Images/WhatsApp Business API vs. Free WhatsApp Business App_ Which One Is Right for You.jpg";
import blog8 from"./Images/10 Proven Marketing Strategies Using WhatsApp  Business API.jpg";
import blog9 from"./Images/How Bangalore’s  Small Businesses Are Leveraging WhatsApp Business API  for Growth.jpg";
import blog10 from"./Images/WhatsApp Business API Integration_ Essential Tools and Platforms.jpg";
import blog11 from"./Images/Why WhatsApp Business API is Revolutionizing  E-commerce in India.jpg";
import blog12 from"./Images/WhatsApp Business API Use Cases Transforming Healthcare in India.jpg";
import blog13 from"./Images/jan1.jpg";  
import blog14 from"./Images/jan2.png";
import blog15 from"./Images/jan3.jpg";
import blog16 from"./Images/jan4.jpg";


const BlogImages = {
  1: blog1,
  2: blog2,
  3: blog3,
  4: blog4,
  5: blog5,
  6: blog6,
  7: blog7,
  9: blog8,
  10: blog9,
  11: blog10,
  12: blog11,
  13: blog12,
  14: blog13,
  15: blog14,
  16: blog15,
  17: blog16,
  
  
};

export default BlogImages;
